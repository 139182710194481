























































import Vue from 'vue'
import { cardSettingService } from '@/services'
import { Page } from '@/types/enums'

type CardSettingForm = {
    name: string,
    visibility: boolean,
    loading: boolean
}

export default Vue.extend({
    data() {
        return {
            cards: {
                'propertyInfo': {
                    name: 'Property Information',
                    visibility: false,
                    loading: true
                },
                'appraisalInfo': {
                    name: 'Appraisal Information',
                    visibility: false,
                    loading: true
                },
                'contacts': {
                    name: 'Contacts',
                    visibility: false,
                    loading: true
                },
                'additionalDetails':{
                    name: 'Additional Details',
                    visibility: false,
                    loading: true
                },
                'leases': {
                    name: 'Leases',
                    visibility: false,
                    loading: true
                },
                'appraisalJobDetails': {
                    name: 'Jobs',
                    visibility: false,
                    loading: true
                }
            } as Record<string, CardSettingForm> ,
            error: false
        }
    },
    async mounted(){
        await this.getPropertyCardSettings()
    },
    methods: {
        async getPropertyCardSettings() {
            const propertyCardSettings = await cardSettingService.getCardSettings(Page.PROPERTY_INFORMATION)

            propertyCardSettings.forEach( cardSetting => {
                if(this.cards[cardSetting.name]) {
                    this.cards[cardSetting.name].visibility = cardSetting.visibility
                    this.cards[cardSetting.name].loading = false
                }
            })
        },
        async saveCardSetting(cardName: string, visibility:boolean) {
            this.cards[cardName].loading = true
            this.error = false

            try{
                await cardSettingService.saveCardSetting({ 
                    page: Page.PROPERTY_INFORMATION,
                    name: cardName,
                    visibility
                })  
            } catch(e) {
                this.error = true
                console.log(e)
                this.cards[cardName].visibility = !visibility
            }
            await this.getPropertyCardSettings()

            this.cards[cardName].loading = false
        }
    }
})
