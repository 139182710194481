<template>
    <v-container>
        <div>
            <v-overlay :value="loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </div>
        <v-row>
            <v-col cols="auto">
                <h2>Gmap Info Popup Settings</h2>
            </v-col>
            <v-col>
                <v-btn @click="save" >save changes</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="4"
            >
                <v-alert
                    text
                    prominent
                    type="error"
                    v-if="form.errors.General"
                >
                    {{ form.errors.General }}
                </v-alert>
                <v-alert
                    text
                    prominent
                    type="success"
                    v-if="form.success"
                >
                    Gmap Info Popup Setting Saved Successfully!
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="auto">
                <v-select
                    :items="pages"
                    label="Page"
                    outlined
                    v-model="form.page"
                    @change="onSelectPageChange"
                    :error-messages="form.errors.Page"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="3"
                align="center"
                justify="center"
            >
                <div
                    id="preview-popup"
                    role="dialog"
                    tabindex="-1"
                    class="gm-style-iw gm-style-iw-c"
                >
                    <div
                        class="gm-style-iw-d"
                        style="max-height: 766px;"
                    >
                        <div>
                            <p style="font-size:16px">
                                <strong>Sample Header</strong><br><br>
                                <template v-for="line in form.body">
                                    <strong :key="`${line.id}_desc`">{{ line.alias }}</strong>: Value of {{ line.alias }} Field
                                    <br :key="`${line.id}_br`">
                                </template>
                                <a href="">View Details</a>
                            </p>
                        </div>
                    </div>
                </div>
            </v-col>     
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="5"
            >
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete
                            :items="fields[form.page]"
                            label="Header"
                            item-text="alias"
                            item-value="name"
                            width
                            v-model="form.header"
                            :error-messages="form.errors.Header"
                            @change="onFormHeaderChange"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row
                    v-for="(line,index) in form.body"
                    :key="line.id"
                >
                    <v-col cols="6">
                        <v-autocomplete
                            :items="fields[form.page]"
                            label="Field"
                            item-text="alias"
                            item-value="name"
                            width
                            :value="form.body[index].field"
                            @input="setPopupInfoBody($event, index)"
                            :error-messages="form.body[index].error.Field"
                            returnObject
                        ></v-autocomplete>
                    </v-col>
                    <v-col
                        cols="6"
                        class="d-flex align-center"
                        style="gap:12px;"
                    >
                        <v-btn
                            color="success"
                            fab
                            small
                            :disabled="form.body.length > 3"
                            @click="onAddFieldClick(index)"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            color="error"
                            fab
                            small
                            :disabled="form.body.length < 2"
                            @click="onRemoveFieldClick(index)"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue'
import { stackGmapService } from '@/services'
import propertyJson from '@/views/Property.json'
import jobsJson from '@/views/Job.json'
import accountingJson from '@/views/Accounting.json'
import { Page } from '@/types/enums'


function generateLineBodyError() {
    return { Field: '', Alias: ''}
}

function generateId() {
    return Math.floor(Math.random() * Date.now())
}

function generateFormBodyEntry() {
    return {'alias': '', 'field': '', id: generateId(), error: generateLineBodyError()}
}

function generateInitFormErrors() {
    return {
        Header: '',
        General: '',
        Page: ''
    }
}

export default Vue.extend({
    components: {
    },
    data(){
        return {
            form: {
                header: '',
                body: [
                    generateFormBodyEntry()
                ],
                page: '',
                errors: generateInitFormErrors(),
                success: false,
            },
            pages: [
                Page.PROPERTY, 
                Page.MAP,
                Page.JOBS,
                Page.ACCOUNTING
            ],
            fields: {
                [Page.PROPERTY]: propertyJson.fields,
                [Page.JOBS]: jobsJson.fields,
                [Page.MAP]: propertyJson.fields,
                [Page.ACCOUNTING]: accountingJson.fields,
                '': []
            },
            loading: false
        }
    },
    methods:{
        async onSelectPageChange() {
            if(!this.form.page) {
                return
            }
            this.loading = true
            await this.getGmapRecordSetting()
            this.loading = false

        },
        async getGmapRecordSetting() {
            const setting = await stackGmapService.getGmapRecordSetting(this.form.page)
            setting.body = setting.body && setting.body.length > 0 ? setting.body : [generateFormBodyEntry()]

            this.form.header = setting.header
            this.form.body = setting.body.map( st => ({...st, id: generateId(), error: generateLineBodyError()}))
            this.form.errors = generateInitFormErrors()
            this.form.success = false
        },
        setPopupInfoBody(chosen, index) {
            const chosenCopy = chosen || generateFormBodyEntry()
            const line = {...this.form.body[index], error: generateLineBodyError()}
            line.field = chosenCopy.name
            line.alias = chosenCopy.alias
            Vue.set(this.form.body, index, line)
        },
        onAddFieldClick(index) {
            this.form.body.splice(index+1, 0, generateFormBodyEntry())
        },
        onRemoveFieldClick(index) {
            this.form.body.splice(index, 1)
        },
        async save() {
            this.form.errors = generateInitFormErrors()
            this.form.body = this.form.body.map(line => ({...line, error: generateLineBodyError()}))
            this.form.success = false
            this.loading = true

            try { 
                await stackGmapService.saveGmapRecordSetting({
                    ...this.form,
                    body: this.form.body.map((x, index) => ({...x, sortOrder: index}))
                })
                await this.getGmapRecordSetting()
                this.form.success = true
            } catch (e) {
                if(e.response.status == 400 && e.response.data && e.response.data.errors) {
                    for (const [key, value] of Object.entries(e.response.data.errors)) {
                        if(key.includes('Body')) {
                            const match = key.match(/Body\[(\d)\]\.(.+)/)
                            if(match) {
                                const index = parseInt(match[1])
                                const field = match[2]
                                const line = {...this.form.body[index]}
                                line.error[field] = value
                                Vue.set(this.form.body, index, line)
                            }
                            
                        } else {
                            this.form.errors[key] = value
                        }
                        
                    }
                    
                    this.form.errors.General = 'There Are errors that require your attention'
                } else {
                    this.form.errors.General = 'We\'re sorry, but we can\'t process your request at this time. Please try again later.'
                }
            }
            this.loading = false
        },
        onFormHeaderChange() {
            this.clearFormError('Header')
        },
        clearFormError(property) {
            if(this.form.errors[property]) {
                this.form.errors[property] = ''
                this.form.errors.General = ''
            }
            
        }
    },
})
</script>

<style>
#preview-popup {
    -webkit-text-size-adjust: 100%;
    word-break: normal;
    tab-size: 4;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.87);
    --border-radius: 4px;
    --font-family: roboto;
    font: 400 11px Roboto, Arial, sans-serif;
    cursor: default;
    background-repeat: no-repeat;
    margin: 0;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 13px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: white;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,.3);
    max-width: 648px;
    max-height: 158px;
    width: fit-content;
    min-width: 0px;
    text-align: left;
}
</style>