









































































































































































































































































































































import Vue from 'vue'
import { themeSettingsService } from '@/services'
import { genericErrorMessage } from '@/constants'


const delay = (ms: number) => new Promise(res => setTimeout(res, ms))

function generateInitFormErrors() {
    return {
        'OtherTheme.Name': '',
        'OtherTheme.Font': '',
        'OtherTheme.Radius': '',
        Logo: '',
        Favicon: '',
        General: '',
    }
}

export default Vue.extend({
    components: {
    },
    data(){
        return {
            loading: false,
            showPicker: false,
            showPicker2: false,
            theme:{
                primary: '#1976D2',
                secondary: '#f5f5f5',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            } as Record<string, any>,
            otherTheme:{
                name:'Propconn',
                font:'roboto',
                customLogo:false,
                radius:'4',
                help:{
                    email:'',
                    cc:'',
                    subject:'',
                    url:'https://',
                    mode:'off',
                },
                suggestion:{
                    email:'',
                    cc:'',
                    subject:'',
                    url:'https://',
                    mode:'off',
                },
            } as Record<string, any>,
            logo: null as null | File,
            favicon: null as null | File,
            errors: generateInitFormErrors()
        }
    },
    async mounted() {
        const baseTheme = await themeSettingsService.getTheme()

        this.theme = {...this.theme, ...baseTheme.theme ?? {}}
        this.otherTheme = {...this.otherTheme, ...baseTheme.otherTheme ?? {}}
    },
    methods:{
        togglePicker(){
            this.showPicker = !this.showPicker
        },
        togglePicker2(){
            this.showPicker2 = !this.showPicker2
        },
        async save(){
            this.loading = true
            this.errors = generateInitFormErrors()

            try {
                const themeSettings = new FormData()
                for(let keyTheme in this.theme) {
                    themeSettings.append(`theme[${keyTheme}]`, this.theme[keyTheme])
                }

                for(const keyOther in this.otherTheme) {
                    const otherThemeValue = this.otherTheme[keyOther]

                    if(typeof otherThemeValue === 'object' ) {
                        for (const childOtherkey in otherThemeValue) {
                            themeSettings.append(`otherTheme[${keyOther}][${childOtherkey}]`, otherThemeValue[childOtherkey])
                        }
                        continue
                    } 

                    themeSettings.append(`otherTheme[${keyOther}]`, otherThemeValue)
                }

                if(this.logo) {
                    themeSettings.append('logo', this.logo)
                }
                
                if(this.favicon){
                    themeSettings.append('favicon', this.favicon)
                }

                try {
                    await themeSettingsService.saveTheme(themeSettings)
                    await delay(1000)
                    location.reload()
                } catch(e) {
                    if(this.axios.isAxiosError(e) && e.response && e.response.status == 400 && e.response.data && e.response.data.errors) {
                        this.errors = e.response.data.errors
                        this.errors.General = 'There are errors that require your attention'
                    } else {
                        this.errors.General = genericErrorMessage
                    }
                }
            } catch(e) {
                console.error(e)
            } finally {
                this.loading = false
            }             
        },
        onLogoChange(e : File) {
            this.logo = e
        },
        onFaviconChange(e: File) {
            this.favicon = e
        }
    },
})
