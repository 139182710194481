<template>
    <v-container>
        <v-row>
            <gmap-icon-setting></gmap-icon-setting>
        </v-row>
        <v-row>
            <gmap-info-popup-setting></gmap-info-popup-setting>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue'
import GmapIconSetting from '@/components/setting/GmapSettings/GmapIconSetting.vue'
import GmapInfoPopupSetting from '@/components/setting/GmapSettings/GmapInfoPopupSetting.vue'


export default Vue.extend({
    components: {
        GmapIconSetting,
        GmapInfoPopupSetting
    },
})
</script>

