





































import CustomTheme from '@/components/setting/CustomTheme.vue'
import GmapSettings from '@/components/setting/GmapSettings/index.vue'
import JumpLinks from '@/components/setting/JumpLinks.vue'
import PropertyCardSettings from '@/components/setting/PropertyCardSettings.vue'
import Vue from 'vue'



export default Vue.extend({
    name: 'Setting',
    mounted() {
        if(this.$route.params.subPage) {
            const route = this.routes.find(x => x.path == this.$route.params.subPage) ?? {tab: 0}
            this.tab = route.tab
        }
    },
    components: {
        CustomTheme,
        JumpLinks,
        GmapSettings,
        PropertyCardSettings
    },
    data(){
        return {
            tab: 0,
            routes: [
                {label: 'Themes', path: '',  tab: 0},
                {label: 'Jump Links', path: 'jump-links',  tab: 1},
                {label: 'Gmap Settings', path: 'gmap-settings',  tab: 2},
                {label: 'Property Card Settings', path: 'property-card-settings',  tab: 3}
            ]
        }
    }
})
