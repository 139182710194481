<template>
    <v-container>
        <v-row>
            <v-col cols="auto">
                <h2>Jump Links</h2>
            </v-col>
            <v-col>
                <v-btn
                    color="secondary"
                    @click="addJumpLink"
                    class="mr-2"
                >
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
                <v-btn @click="save">save changes</v-btn>
            </v-col>
        </v-row>
        <v-row
            class="mb-2"
            v-for="(jumpLink,i) in jumpLinks "
            :key="i"
        >
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="auto">
                            <v-btn
                                small
                                @click="remove(i)"
                                color="secondary"
                            >
                                <v-icon small>
                                    mdi-trash-can
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-text-field
                                v-model="jumpLink.label"
                                label="button label"
                            />
                        </v-col>
                        <v-col cols="auto">
                            <v-autocomplete
                                v-model="jumpLink.location"
                                label="button shows on this page"
                                :items="['property','tenant']"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <template v-for="(part, index) in jumpLink.parts">
                            <v-col
                                v-if="part.type=='text'"
                                class="ma-0 py-0 px-1"
                                cols="auto"
                                v-bind:key="index"
                            >
                                <v-text-field
                                    name="Name"
                                    v-model="part.value"
                                    type="text"
                                    class="mx-0 px-0"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                v-else-if="part.type=='dropdown'"
                                class="ma-0 py-0 px-1"
                                cols="auto"
                                v-bind:key="index"
                            >
                                <v-autocomplete
                                    auto-select-first
                                    v-model="part.value"
                                    :items="fields[jumpLink.location].fields"
                                    item-text="alias"
                                    item-value="name"
                                    class="mx-0 px-0"
                                ></v-autocomplete>
                            </v-col>
                        </template>
                        <v-col>
                            <v-btn-toggle dense>
                                <v-btn
                                    @click="addText(i)"
                                    color="secondary"
                                >
                                    <v-icon>
                                        mdi-form-textbox
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    @click="addDropdown(i)"
                                    color="secondary"
                                >
                                    <v-icon>
                                        mdi-form-select
                                    </v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-btn
                                class="mr-2"
                                color="secondary"
                            >
                                {{jumpLink.label}}
                            </v-btn>
                            <template v-for="(part, index) in jumpLink.parts">
                                <span
                                    v-if="part.type=='text'"
                                    v-bind:key="index"
                                    style="padding: 0 0px"
                                    v-text="part.value"
                                >
                                </span><span
                                    v-else-if="part.type=='dropdown' && part.value"
                                    class="primary--text"
                                    style="outline: 1px #4e8fca solid;padding:0 3px;margin:0 3px"
                                    v-bind:key="index"
                                    v-text="part.value"
                                >
                                </span>
                            </template>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue'
import property from '@/views/Property.json'
import tenant from '@/views/Tenant.json'
import { debounce } from 'lodash'
import { jumpLinksService } from '@/services'

export default Vue.extend({
    components: {
    },
    data(){
        return {
            fields:{
                property:property,
                tenant:tenant
            },
            jumpLinks:[]
        }
    },
    async mounted() {
        this.jumpLinks = (await jumpLinksService.getJumpLinks())??[]
    },
    methods:{
        addJumpLink() {
            const jumpLink = {
                label:'',
                location:'',
                parts:[{type:'text',value:'https://'}],
            }

            this.jumpLinks.push(jumpLink)
        },
        remove(i){
            console.log(this.jumpLinks)
            this.jumpLinks.splice(i,1)
            console.log(this.jumpLinks)
        },
        addText(i){
            this.jumpLinks[i].parts.push({type:'text',value:''})
        },
        addDropdown(i){
            this.jumpLinks[i].parts.push({type:'dropdown',value:''})
        },
        save:debounce(async function(){
            await jumpLinksService.saveJumpLinks(this.jumpLinks)
            location.reload()
        },1000),
    },
})
</script>
