












































































































































































































































































import Vue from 'vue'
import icons from '@/components/setting/GmapSettings/tablerIcons.json'
import { stackGmapService } from '@/services'
import { Page } from '@/types/enums'
import { StackGmapIconSetting } from '@/types/StackGmapServiceType'
import { SearchConfigField } from '@/types/utilTypes'

import propertyJson from '@/views/Property.json'
import jobsJson from '@/views/Job.json'
import accountingJson from '@/views/Accounting.json'
import { genericErrorMessage } from '@/constants'

type InitForm = {
    iconForm: {
        iconField?: string | null,
        iconFieldValue?: string | null,
        iconColor?: string | null,
        icon?: string | null,
        showIconColorPicker?: boolean | null
    }
    backgroundForm: {
        backgroundField?: string | null,
        backgroundFieldValue?: string | null,
        backgroundColor?: string | null,
        showBackgroundColor?: boolean | null
    },
    generalForm: {
        page?: string | null
    }
}

function generateInitFormErrors() {
    return {
        IconField: '',
        IconFieldValue: '',
        IconColor: '',
        Icon: '',
        BackgroundField: '',
        BackgroundFieldValue: '',
        BackgroundColor: '',
        General: '',
    }
}

function generateForm(initForm: InitForm) {
    const {
        iconForm,
        backgroundForm,
        generalForm
    } = initForm

    return {
        page:generalForm.page ?? '',
        // Icon
        iconForm: {
            iconField: iconForm.iconField ?? '',
            iconFieldValue: iconForm.iconFieldValue ?? 'Default',
            iconColor: iconForm.iconColor ?? '',
            icon: iconForm.icon ?? '',
            showIconColorPicker: false,
        },
        backgroundForm: {
            // Background
            backgroundField: backgroundForm.backgroundField ?? '',
            backgroundFieldValue: backgroundForm.backgroundFieldValue ?? 'Default',
            backgroundColor: backgroundForm.backgroundColor ?? '',
            showBackgroundColor: false,
        },
        // Others
        errors: generateInitFormErrors() as Record<string,string>,
        success: false
    }
}

export default Vue.extend({
    components: {
    },
    data(){
        return {
            iconFieldValues: ['Default'],
            backgroundFieldValues: ['Default'],
            icons: icons,
            form: generateForm({iconForm: {}, backgroundForm: {}, generalForm: {}}),
            loading: false,
            fields: {
                [Page.PROPERTY]: propertyJson.fields,
                [Page.JOBS]: jobsJson.fields,
                [Page.ACCOUNTING]: accountingJson.fields,
                '': []
            } as Record<string, SearchConfigField[]>,
            iconSetting: null as StackGmapIconSetting | null,
            pages: [Page.PROPERTY, Page.JOBS, Page.ACCOUNTING]
        }
    },
    async mounted() {
        await this.getIconSetting()
        await this.populateIconAndBackgroundFormValuesOptions()
        
        this.initializeForm()
    },
    methods:{
        async getIconSetting() {
            if(!this.form.page) {
                return
            }
            this.iconSetting = await stackGmapService.getIconSetting(this.form.page as Page)
        },
        async getIconFieldValues(chosenField: string) {
            this.iconFieldValues = await stackGmapService.getGmapFieldValues(this.form.page, chosenField)
            this.iconFieldValues.push('Default')
        },
        async getBackgroundFieldValues(chosenField: string) {
            this.backgroundFieldValues = await stackGmapService.getGmapFieldValues(this.form.page, chosenField)
            this.backgroundFieldValues.push('Default')
        },
        initializeForm() {
            const iconForm = this.generateIconForm(this.iconSetting?.iconField, this.form.iconForm.iconFieldValue)
            const backgroundForm = this.generateBackgroundForm(this.iconSetting?.backgroundField, this.form.backgroundForm.backgroundFieldValue)

            this.form = generateForm({
                iconForm,
                backgroundForm,
                generalForm: {}
            })
        },
        generateIconForm(iconField?: string| null, iconFieldValue?: string | null) {

            let iconMetadata = {
                iconColor: '',
                icon: ''
            }
            
            if(this.iconSetting && this.iconSetting?.iconField === iconField && iconFieldValue) {
                iconMetadata = this.iconSetting.iconSettingMetadata[iconFieldValue]
            }

            return {
                iconField,
                iconFieldValue,
                iconColor: iconMetadata?.iconColor,
                icon: iconMetadata?.icon,
            }
        },
        generateBackgroundForm(backgroundField?: string| null, backgroundFieldValue?: string | null) {
            let backgroundMetadata = {
                backgroundColor:'',
            } 

            if(this.iconSetting && this.iconSetting?.backgroundField === backgroundField && backgroundFieldValue) {
                backgroundMetadata = this.iconSetting.backgroundSettingMetadata[backgroundFieldValue]
            }

            return {
                backgroundField,
                backgroundFieldValue,
                backgroundColor: backgroundMetadata?.backgroundColor,
            }  
        },
        async onIconFieldChange(selectValue:SearchConfigField | null) {
            this.loading = true
            await this.getIconSetting()
            await this.getIconFieldValues(selectValue ? selectValue.alias : 'None')
            this.form = generateForm({
                iconForm: await this.generateIconForm(this.form.iconForm.iconField, 'Default'), 
                backgroundForm: this.form.backgroundForm,
                generalForm: {
                    page: this.form.page
                }
            })
            this.loading = false
        },
        async onIconFieldValueChange() {
            this.loading = true
            await this.getIconSetting()
            this.form = generateForm({
                iconForm: this.generateIconForm(this.form.iconForm.iconField, this.form.iconForm.iconFieldValue), 
                backgroundForm: this.form.backgroundForm,
                generalForm: {
                    page: this.form.page
                }
            })
            this.loading = false
        },
        async onIconBackgroundFieldChange(selectValue: SearchConfigField | null) {
            this.loading = true
            await this.getIconSetting()
            await this.getBackgroundFieldValues(selectValue ? selectValue.alias : 'None')
            this.form = generateForm({
                iconForm: this.form.iconForm,
                backgroundForm: this.generateBackgroundForm(this.form.backgroundForm.backgroundField, 'Default'),
                generalForm: {
                    page: this.form.page
                }
            })
            this.loading = false
        },
        async onIconBackgroundFieldValueChange() {
            this.loading = true
            await this.getIconSetting()
            this.form = generateForm({
                iconForm:  this.form.iconForm,
                backgroundForm: this.generateBackgroundForm(this.form.backgroundForm.backgroundField, this.form.backgroundForm.backgroundFieldValue),
                generalForm: {
                    page: this.form.page
                }
            })
            this.loading = false
        },
        onIconChange(){
            this.clearFormError('Icon')
        },
        onBackgroundColorChange() {
            this.clearFormError('BackgroundColor')
        },
        onIconColorChange() {
            this.clearFormError('IconColor')
        },
        clearFormError(property: string) {
            if(this.form.errors[property]) {
                this.form.errors[property] = ''
                this.form.errors.General = ''
            }
            
        },
        async onSettingSave() {
            this.form.errors = generateInitFormErrors()
            this.form.success = false
            this.loading = true


            try {
                await stackGmapService.saveIconSetting({
                    page: this.form.page as Page,
                    ...this.form.iconForm,
                    ...this.form.backgroundForm
                })
                await this.getIconSetting()
                this.form.success = true
            } catch (e:any) {
                
                if(this.axios.isAxiosError(e) && e.response && e.response.status == 400 && e.response.data && e.response.data.errors) {
                    this.form.errors = e.response.data.errors
                    this.form.errors.General = 'There are errors that require your attention'
                } else {
                    this.form.errors.General = genericErrorMessage
                }
               
            }
            
            this.loading = false
        },
        async populateIconAndBackgroundFormValuesOptions() {
            if (this.iconSetting){ 
                let iconFieldAlias = {
                    found: !this.iconSetting.iconField,
                    value: ''
                }
                let backgroundFieldAlias = {
                    found: !this.iconSetting.backgroundField,
                    value: ''
                }

                for(const field of this.fields[this.form.page]) {
                    if (iconFieldAlias.found && backgroundFieldAlias.found) {
                        break
                    }

                    if(!iconFieldAlias.found && this.iconSetting.iconField === field.name) {
                        iconFieldAlias.value = field.alias
                    }

                    if(!backgroundFieldAlias.found && this.iconSetting.backgroundField === field.name) {
                        backgroundFieldAlias.value = field.alias
                    }
                }

                if(iconFieldAlias.value) {
                    await this.getIconFieldValues(iconFieldAlias.value)
                }
            
                if(backgroundFieldAlias.value) {
                    await this.getBackgroundFieldValues(backgroundFieldAlias.value)
                }
            }
        },
        async onSelectPageChange() {
            if(!this.form.page) {
                return
            }
            this.loading = true
            this.form.errors = generateInitFormErrors()
            await this.getIconSetting()
            await this.populateIconAndBackgroundFormValuesOptions()
            
            const iconForm = this.generateIconForm(this.iconSetting?.iconField, this.form.iconForm.iconFieldValue)
            const backgroundForm = this.generateBackgroundForm(this.iconSetting?.backgroundField, this.form.backgroundForm.backgroundFieldValue)

            this.form = generateForm({
                iconForm,
                backgroundForm,
                generalForm: {page: this.form.page}
            })
            this.loading = false

        }
    },
})
